import React, { useState } from 'react'
import {
  MDBAvatar,
  MDBCard,
  MDBCardBody,
  MDBCardUp,
  MDBIcon,
  MDBLink,
  MDBRotatingCard,
} from 'mdbreact'
import { recordEvent } from '../../Common/metrics'

export const Darcy = () => {
  const [darcyFlipped, setDarcyFlipped] = useState(false)
  const flipCard = async (state: boolean) => {
    if (state) {
      await recordEvent('about-us/darcy')
    }
    setDarcyFlipped(state)
  }

  const cardStyle = { height: '26rem' }

  return (
    <MDBRotatingCard flipped={darcyFlipped}>
      <MDBCard className="face front" style={cardStyle}>
        <MDBCardUp
          style={{
            backgroundImage:
              "url('https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg')",
          }}
        >
          <div className="card-img-top h-100 w-100 rgba-black-light">&nbsp;</div>
        </MDBCardUp>
        <MDBAvatar className="mx-auto white" circle>
          <img
            src="https://media-exp1.licdn.com/dms/image/C4D03AQH0IFnyvDK1tw/profile-displayphoto-shrink_100_100/0?e=1593043200&v=beta&t=hZy_YwohkIKcS_i829oVWNHmUJr7t9kLxltoVH2Mdlg"
            alt=""
            className="rounded-circle"
          />
        </MDBAvatar>
        <MDBCardBody>
          <h4 className="font-weight-bold mb-3">Darcy Buttrose</h4>
          <p className="font-weight-bold blue-text">Owner / Principal Consultant</p>
          <MDBLink className="rotate-btn" data-card="card-1" onClick={() => flipCard(true)}>
            <MDBIcon icon="redo" /> Click here to rotate
          </MDBLink>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="face back" style={cardStyle}>
        <MDBCardBody>
          <h4 className="font-weight-bold">About me</h4>
          <hr />
          <p
            className="scrollbar scrollbar-primary"
            style={{ maxHeight: '12rem', minHeight: '10rem' }}
          >
            I am a Software Engineer and Team Leader with more than 25 years’ experience in the IT
            Industry. I specialise in Test and Behavior Driven Development. My development languages
            of choice are JavaScript(Typescript) and C#. I have over 5 years’ experience working in
            Agile environments and I am qualified as a Scrum Master. I am proficient in frameworks
            that encourage test driven development. I use Typescript, Inversify, RequireJS, Webpack,
            Jasmine, mocha and chai when doing Javascript development. I use C#, AutoFac,
            NSubstitute, and Ninject for .Net development. I am proficient in JavaScript frameworks
            such as AngularJS, KnockoutJS, and React as well as C# WebAPI, and WebSockets. Although
            my passion is web based application development, I am happy working on desktop and
            mobile applications. I have experience working with WPF as well as native Android
            development with Java.
          </p>
          <hr />
          <ul className="list-inline py-2">
            <li className="list-inline-item">
              <a href="https://www.facebook.com/darcy.buttrose" className="p-2 fa-lg fb-ic">
                <MDBIcon icon="facebook" brand />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/in/darcy-buttrose-1765b725/"
                className="p-2 fa-lg li-ic"
              >
                <MDBIcon icon="linkedin" brand />
              </a>
            </li>
          </ul>
          <MDBLink className="rotate-btn" data-card="card-1" onClick={() => flipCard(false)}>
            <MDBIcon icon="undo" /> Click here to rotate back
          </MDBLink>
        </MDBCardBody>
      </MDBCard>
    </MDBRotatingCard>
  )
}
