import React, { FunctionComponent, useState } from 'react'
import { MDBCard, MDBCardBody, MDBCardUp, MDBIcon, MDBLink, MDBRotatingCard } from 'mdbreact'
import { ScrollableDescription } from './scrollableDescription'
import { recordEvent } from '../../Common/metrics'

interface IProps {
  title: string
  imageUrl: string
  imageSize: 'contain' | 'cover'
  company: string
}

export const ProjectCard: FunctionComponent<IProps> = ({
  company,
  title,
  imageUrl,
  imageSize,
  children,
}) => {
  const [flipped, setFlipped] = useState(false)

  const showDetails = async (state: boolean) => {
    if (state) {
      await recordEvent(`projects/${company}`)
    }
    setFlipped(state)
  }

  const cardStyle = { height: '24rem' }

  return (
    <MDBRotatingCard flipped={flipped}>
      <MDBCard className="face front" style={cardStyle}>
        <MDBCardUp
          style={{
            backgroundImage: `url('${imageUrl}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: imageSize,
            backgroundPosition: 'center',
          }}
        >
          <div className="card-img-top h-100 w-100 rgba-black-light">&nbsp;</div>
        </MDBCardUp>
        <MDBCardBody>
          <h4 className="font-weight-bold mb-3">{company}</h4>
          <p className="font-weight-bold blue-text">{title}</p>
          <MDBLink className="rotate-btn" data-card="card-1" onClick={() => showDetails(true)}>
            <MDBIcon icon="redo" /> Click here to rotate
          </MDBLink>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="face back" style={cardStyle}>
        <MDBCardBody>
          <h4 className="font-weight-bold">About this project</h4>
          <hr />
          <ScrollableDescription>{children}</ScrollableDescription>
          <hr />
          <MDBLink className="rotate-btn" data-card="card-1" onClick={() => showDetails(false)}>
            <MDBIcon icon="undo" /> Click here to rotate back
          </MDBLink>
        </MDBCardBody>
      </MDBCard>
    </MDBRotatingCard>
  )
}
