import React, { useState } from 'react'
import {
  MDBCollapse,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBNavItem,
  MDBNavLink,
} from 'mdbreact'
import { useHistory, useLocation } from 'react-router-dom'
import { recordEvent } from '../../Common/metrics'

export const Header = () => {
  const [collapse, setCollapse] = useState(false)
  const [isWideEnough] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const currentPage = location.pathname
  const homePage = () => currentPage === '/'

  const onClick = () => {
    setCollapse(!collapse)
  }

  const goTo = async (page: string) => {
    await recordEvent(page)
    history.push(page)
    setCollapse(false)
  }

  return (
    <header>
      <MDBNavbar color="white" expand="md" fixed="top" transparent={homePage()}>
        <MDBNavbarBrand onClick={() => goTo('/')} href="/">
          <i className="fab fa-edge" />
          &nbsp;
          <strong>CMIT International</strong>
        </MDBNavbarBrand>
        {!isWideEnough && (
          <MDBNavbarToggler
            onClick={onClick}
            className={homePage() ? 'navbar-dark' : 'navbar-light'}
          />
        )}
        <MDBCollapse isOpen={collapse} navbar>
          <MDBNavbarNav left>
            <MDBNavItem active={currentPage === '/'}>
              <MDBNavLink onClick={() => goTo('/')} to="/">
                Home
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={currentPage === '/services'}>
              <MDBNavLink onClick={() => goTo('/services')} to="/services">
                Services
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={currentPage === '/projects'}>
              <MDBNavLink onClick={() => goTo('/projects')} to="/projects">
                Projects
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={currentPage === '/about'}>
              <MDBNavLink onClick={() => goTo('/about')} to="/about">
                About Us
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active={currentPage === '/contact'}>
              <MDBNavLink onClick={() => goTo('/contact')} to="/contact">
                Contact Us
              </MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    </header>
  )
}
