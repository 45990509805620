import React from 'react'
import { MDBContainer, MDBMask, MDBView } from 'mdbreact'
import { Route, Switch, useLocation } from 'react-router-dom'
import { Home } from '../../Pages/Home'
import { About } from '../../Pages/About'
import { Services } from '../../Pages/Services'
import { Contact } from '../../Pages/Contact'

import './App.scss'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { Projects } from '../../Pages/Projects'
import { recordEvent } from '../../Common/metrics'

export const App = () => {
  const location = useLocation()

  const page = location.pathname
  const homePage = () => page === '/'

  return (
    <div id={homePage() ? 'parallaxintro' : 'sectionpage'}>
      <Header />

      <MDBView
        src={
          homePage()
            ? 'https://i.pinimg.com/originals/e5/07/71/e5077124767c1acfbb8fcc84c918a613.jpg'
            : ''
        }
        fixed
      >
        {homePage() ? <MDBMask className="rgba-black-light" /> : null}
        <section style={{ minHeight: '56px' }} />
        <MDBContainer
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100vh', minWidth: '100vw' }}
        >
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
          </Switch>
        </MDBContainer>
        <section style={{ minHeight: '27px' }} />
      </MDBView>

      <Footer />
    </div>
  )
}
