import React from 'react'
import { ProjectCard } from './projectCard'

export const Rwwa = () => {
  return (
    <ProjectCard
      title="Tab Touch (2014 - 2016)"
      imageUrl="https://www.rwwa.com.au/wp-content/uploads/2019/05/Banner2.jpg"
      company="Racing And Wagering WA"
      imageSize="cover"
    >
      <p>
        CMIT International was commissioned to Racing and Wagering Western Australia (RWWA) in July
        2014 to provide Software Engineer resources. After 6 months CMIT International was requested
        to provide Team Lead resources.
      </p>
      <p>
        The frameworks and technologies introduced under recommendation by CMIT International were
        Typescript, RequireJS, KnockoutJS, Jasmine, Node, JockeyJS, JQuery, ajax and ASP.Net WebAPI.
        Other recommendation introduced was an increase in the use of javascript based frameworks
        and technologies to improve performance, use experience, and testing. Typescript was
        recommended to to enforce type safe code, Dependency Injection was recommended for both C#
        and Typescript code to improve decoupling, single responsibility, and testability. Feature
        toggle technologies was recommended for both the server and client side to reduce the need
        for code branching during feature development and testing of new features with real users.
        The Android application that hosted the client side of the application was enhanced with
        JockeyJS to allow the native Android code to communicate with Web application typescript
        code. This allowed our web based application access to native mobile phone hardware for
        accessing GeoLocation hardware such as GPS, WiFi, Bluetooth, and Audio.
      </p>
    </ProjectCard>
  )
}
