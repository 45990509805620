import React from 'react'
import { MDBContainer, MDBFooter } from 'mdbreact'
import { useLocation } from 'react-router-dom'

export const Footer = () => {
  const location = useLocation()

  const currentPage = location.pathname
  const homePage = () => currentPage === '/'

  return (
    <MDBFooter>
      <div
        className="footer-copyright text-center py-3"
        style={homePage() ? { backgroundColor: 'rgba(0,0,0,0)' } : {}}
      >
        <MDBContainer fluid>&copy; 2020 Copyright: CMIT International Pty Ltd</MDBContainer>
      </div>
    </MDBFooter>
  )
}
