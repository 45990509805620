import React, { FunctionComponent } from 'react'

export const ScrollableDescription: FunctionComponent = (props) => {
  return (
    <div
      className="scrollbar scrollbar-primary text-left"
      style={{ maxHeight: '12rem', minHeight: '10rem' }}
    >
      {props.children}.
    </div>
  )
}
