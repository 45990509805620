import React from 'react'
import { MDBCol, MDBRow } from 'mdbreact'

export const Home = () => {
  return (
    <MDBRow>
      <MDBCol md="12" className="mb-4 white-text text-center">
        <h1 className="display-4 mb-0 pt-md-5 pt-5 white-text font-weight-bold">
          CMIT
          <span className="indigo-text font-weight-bold"> International</span>
        </h1>
        <hr className="hr-light my-4" />
        <h5 className="text-uppercase white-text font-weight-bold">
          Web site and Software creation using clever engineering.
        </h5>
      </MDBCol>
    </MDBRow>
  )
}
