import React from 'react'
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact'
import { Bhp } from './bhp'
import { Vix } from './vix'
import { Icetana } from './icetana'
import { Pag } from './pag'
import { Rwwa } from './rwwa'
import { ManchesterUnity } from './manchester'

export const Projects = () => {
  const groupStyle = { minHeight: '26rem' }

  return (
    <MDBContainer fluid tag="section" className="text-center">
      <h2 className="h1-responsive font-weight-bold my-5">Projects</h2>
      <p className="grey-text w-responsive mx-auto mb-5">
        20 years providing consultation services to major projects.
      </p>

      <MDBRow className="text-center">
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <Vix />
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <Pag />
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <Icetana />
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <Bhp />
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <Rwwa />
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <ManchesterUnity />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
