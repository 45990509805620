import React from 'react'
import {
  MDBCard,
  MDBCardGroup,
  MDBCardTitle,
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBCol,
  MDBRow,
} from 'mdbreact'
import { useHistory } from 'react-router-dom'
import { recordEvent } from '../../Common/metrics'

export const Services = () => {
  const groupStyle = { minHeight: '26rem' }
  const history = useHistory()

  const goToContact = async (service: string) => {
    await recordEvent(`services/${service}`)
    history.push('/contact')
  }

  return (
    <MDBContainer tag="section" className="text-center">
      <h2 className="h1-responsive font-weight-bold my-5">Services</h2>
      <p className="lead w-responsive mx-auto mb-5">
        The design and construction of dynamic and responsive web applications.
      </p>
      <MDBRow>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <MDBCard
            className="card-image"
            style={{
              backgroundImage: "url('https://mdbootstrap.com/img/Photos/Others/images/89.jpg')",
            }}
          >
            <div className="text-white text-center d-flex justify-content-center align-items-stretch rgba-red-light py-5 px-4 h-100">
              <div>
                <h5 className="deep-purple-text">
                  <MDBIcon icon="paint-brush" /> Consultation
                </h5>
                <MDBCardTitle tag="h3" className="pt-2">
                  <strong>We Can Consult On</strong>
                </MDBCardTitle>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">Agile Projects</MDBCol>
                    <MDBCol size="12">Web Site Projects</MDBCol>
                    <MDBCol size="12">Web API Projects</MDBCol>
                    <MDBCol size="12">AWS Serverless Projects</MDBCol>
                    <MDBCol size="12">Any Combination Of These</MDBCol>
                  </MDBRow>
                </MDBContainer>
                <MDBBtn color="deep-purple" onClick={() => goToContact('consult')}>
                  <MDBIcon icon="clone left" /> Contact Us
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <MDBCard
            className="card-image"
            style={{
              backgroundImage:
                "url('https://mdbootstrap.com/img/Photos/Others/screens-section.jpg')",
            }}
          >
            <div className="text-white text-center d-flex justify-content-center align-items-stretch rgba-indigo-strong py-5 px-4 h-100">
              <div>
                <h5 className="orange-text">
                  <MDBIcon icon="paint-brush" /> Design
                </h5>
                <MDBCardTitle tag="h3" className="pt-2">
                  <strong>We Design With</strong>
                </MDBCardTitle>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">HTML5 Power</MDBCol>
                    <MDBCol size="12">CSS3 Awesomeness</MDBCol>
                    <MDBCol size="12">Material Design Principles</MDBCol>
                  </MDBRow>
                </MDBContainer>
                <MDBBtn color="deep-orange" onClick={() => goToContact('design')}>
                  <MDBIcon icon="clone left" /> Contact Us
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="4" md="6" sm="12" style={groupStyle}>
          <MDBCard
            className="card-image"
            style={{
              backgroundImage:
                "url('https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg')",
            }}
          >
            <div className="text-white text-center d-flex align-content-center align-items-center rgba-black-strong py-5 px-4 h-100">
              <div>
                <h5 className="pink-text">
                  <MDBIcon icon="desktop" /> Software
                </h5>
                <MDBCardTitle tag="h3" className="pt-2">
                  <strong>We Create</strong>
                </MDBCardTitle>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">React Apps</MDBCol>
                    <MDBCol size="12">Angular Apps</MDBCol>
                    <MDBCol size="12">DotNet Core Web APIs</MDBCol>
                    <MDBCol size="12">NodeJs Backed Web APIs</MDBCol>
                    <MDBCol size="12">AWS Serverless Functions</MDBCol>
                    <MDBCol size="12">AWS Data Storage Solutions</MDBCol>
                  </MDBRow>
                </MDBContainer>
                <MDBBtn color="pink" onClick={() => goToContact('create')}>
                  <MDBIcon icon="clone left" /> Contact Us
                </MDBBtn>
              </div>
            </div>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
