import React from 'react'
import { ProjectCard } from './projectCard'

export const Vix = () => {
  return (
    <ProjectCard
      title="Pulse (2018 - 2020)"
      imageUrl="https://media-exp1.licdn.com/dms/image/C4D1BAQFj60naMGf34w/company-background_10000/0?e=2159024400&v=beta&t=QgoVFyipvKYEVaOACd0EOLNXje4P5ti85aYTb80F2tA"
      company="VIX Technologies"
      imageSize="cover"
    >
      <p>
        CMIT International was commissioned by Vix help with the transformation of the Admin Portal
        used by Vix clients. CMIT International provided mentoring and coaching to developers as
        well as designing and implementing new components of the Admin Portal. One of the major
        contributions was to rewrite the UI testing platform used by the Portal to include
        dependency injection and asynchronous calls. This increased its stability and efficiency by
        over 100%. We also provided AWS skills to enhance some of the lambda functions used by the
        Pulse product. The enhancements allowed the lambda functions to better handle errors and
        other edge cases. The Vix Pulse product uses AWS technologies heavily so having AWS skills
        was a definite advantage. While at Vix I was designated as one of the AWS power user roles
        and I was responsible for the stability of my team's AWS environment.
      </p>
    </ProjectCard>
  )
}
