import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import { Darcy } from './darcy'
import { Marta } from './marta'
import { Maksym } from './maksym'

export const About = () => {
  const groupStyle = { minHeight: '28rem' }

  return (
    <MDBContainer tag="section" className="text-center">
      <h2 className="h1-responsive font-weight-bold my-5">Our amazing team</h2>
      <p className="grey-text w-responsive mx-auto mb-5">
        CMIT International is currently a one man operation.
      </p>
      <MDBRow>
        <MDBCol lg="4" md="3" sm="1">
          &nbsp;
        </MDBCol>
        <MDBCol style={groupStyle} lg="4" md="6" sm="10">
          <Darcy />
        </MDBCol>
        <MDBCol lg="4" md="3" sm="1">
          &nbsp;
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
