import React from 'react'
import { ProjectCard } from './projectCard'

export const Icetana = () => {
  return (
    <ProjectCard
      company="iCetana"
      title="Video Surveillance Management (2018)"
      imageUrl="https://inxo-s3-cdn.inxero.com/26536-1qIKyosJG8/icetana-corporate-flyer-a4_4pp_email_us-version_oct2018-email.pdf_thumb_rect_large320x180.jpg"
      imageSize="cover"
    >
      CMIT International was commissioned for two months to helped introduce and implement implement
      industry best practices for a new product being developed. The practices implemented were Test
      Driven Development, Continuous Integration, and Automated Deployment. Some of the tools used
      to implement these practices were Jenkins and Docker. development platform used for the new
      product included NodeJs, React, Jest, Docker, and MySql.
    </ProjectCard>
  )
}
