import React from 'react'
import { ProjectCard } from './projectCard'

export const ManchesterUnity = () => {
  return (
    <ProjectCard
      company="Manchester Unity"
      title="Micro Services (2002 - 2006)"
      imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS7bOq7XYv8pHGM1EyaR1dOtYHu2KbM6XdUA74Bb_02zZbfwt_i&usqp=CAU"
      imageSize="contain"
    >
      CMIT International was commissioned by Manchester Unity in 2002 to develop and support a
      Services platform to allow their superannuation subsidiaries to connect to their core banking
      services. This was accomplished using IIS, Oracle, and ASP. The development of the platform
      was completed in 2003 and the support contract ran up until 2006.
    </ProjectCard>
  )
}
