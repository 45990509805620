import React from 'react'
import { ProjectCard } from './projectCard'

export const Bhp = () => {
  return (
    <ProjectCard
      company="BHP"
      title="Asset Incident Management System (2016 - 2017)"
      imageUrl="https://www.bhp.com/-/media/images/2017/171113_olympicdam_lq.jpg?h=844&w=1500&la=en&hash=8DD221A12BBD2336D199A2372BBE2DC609D8DD14"
      imageSize="cover"
    >
      During 2016 and 2017, CMIT International was involved in the Design and Implementation of
      changes to the Asset Integrity Management System for BHP. This system is built on a Microsoft
      .Net Core backend with a Angular 4 Typescript frontend. Major pieces of work implemented were
      the migration of the frontend client from Angular 2 to Angular 4 as well as the design and
      implementation of the feature toggle mechanisms for the application.
    </ProjectCard>
  )
}
