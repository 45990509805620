import React from 'react'
import { ProjectCard } from './projectCard'

export const Pag = () => {
  return (
    <ProjectCard
      title="Omniflow (2016 - 2020)"
      imageUrl="https://media-exp1.licdn.com/dms/image/C511BAQH7fO66625cyg/company-background_10000/0?e=2159024400&v=beta&t=QK11w63cHRQvxwm62wllaNCc21vnl3ctzWIYMo1lPZY"
      company="Process Automation Group"
      imageSize="cover"
    >
      <p>
        CMIT International was contracted by Process Automation Group (PAG) in June 2016 to lead the
        construction of the Omniflow web application that was being prepared for market. This web
        application is a client-based SPA written in Javascript, React, Redux, and Bootstrap. The
        WebAPI for the application is a partially REST compliant web service written in C# and
        hosted on IIS.
      </p>
      <p>
        During this time at Process Automation Group we introduced the use of TDD, dependency,
        injection, code reviews, and automation of testing and building via a CI server. The
        introduction of these tools and techniques allowed the deployment of new software multiple
        times in one day where previously this was not possible. The CI server was set up to
        automate deployment of multiple versions of the software onto the Azure test environment.
      </p>
    </ProjectCard>
  )
}
