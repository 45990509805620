import React, { useState } from 'react'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
} from 'mdbreact'
import { recordEvent } from '../../Common/metrics'

export const Contact = () => {
  const [name, setName] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [email, setEmail] = useState('')
  const [sendStatus, setSendStatus] = useState(0)

  const sendEmail = async () => {
    try {
      setSendStatus(1)
      await recordEvent('send-email')
      await fetch('https://api.cmitinternational.com.au/email', {
        method: 'POST',
        mode: 'no-cors',
        body: JSON.stringify({
          name,
          subject,
          message,
          email,
        }),
      })
      setName('')
      setEmail('')
      setSubject('')
      setMessage('')
      setSendStatus(0)
      console.log(`send success`)
    } catch (err) {
      setSendStatus(0)
      console.error(err)
    }
  }

  return (
    <MDBContainer tag="section" className="text-center">
      <h2 className="h1-responsive font-weight-bold my-5">Please get in touch</h2>
      <p className="grey-text w-responsive mx-auto mb-5">
        Drop us a line and we can talk about your requierments.
      </p>
      <MDBRow className="text-left">
        <MDBCol lg="5" className="lg-0 mb-4">
          <MDBCard>
            <MDBCardBody>
              <div className="form-header blue accent-1">
                <h3 className="mt-2">
                  <MDBIcon icon="envelope" /> Write to us:
                </h3>
              </div>
              <p className="dark-grey-text">
                One of our team will be in contact to talk about your requirements.
              </p>
              <div className="md-form">
                <MDBInput
                  icon="user"
                  label="Your name"
                  iconClass="grey-text"
                  type="text"
                  id="form-name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </div>
              <div className="md-form">
                <MDBInput
                  icon="envelope"
                  label="Your email"
                  iconClass="grey-text"
                  type="text"
                  id="form-email"
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </div>
              <div className="md-form">
                <MDBInput
                  icon="tag"
                  label="Subject"
                  iconClass="grey-text"
                  type="text"
                  id="form-subject"
                  value={subject}
                  onChange={(e) => setSubject(e.currentTarget.value)}
                />
              </div>
              <div className="md-form">
                <MDBInput
                  icon="pencil-alt"
                  label="Message"
                  iconClass="grey-text"
                  type="textarea"
                  id="form-text"
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                />
              </div>
              <div className="text-center">
                {sendStatus === 0 ? (
                  <MDBBtn onClick={() => sendEmail()} color="light-blue">
                    Submit
                  </MDBBtn>
                ) : (
                  <MDBBtn color="light-blue">Sending!</MDBBtn>
                )}
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol lg="7">
          <div
            id="map-container"
            className="rounded z-depth-1-half map-container"
            style={{ height: '400px' }}
          >
            <iframe
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCN5Kbjwq6eZFEruF4cWquigPhnwTYlcAQ&q=129+Garratt+Rd,+Bayswater+WA+6053"
              title="This is a unique title"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
            />
          </div>
          <br />
          <MDBRow className="text-center">
            <MDBCol md="3">
              <MDBBtn tag="a" floating color="blue" className="accent-1">
                <MDBIcon icon="map-marker-alt" />
              </MDBBtn>
              <p>Bayswater, 6053</p>
              <p className="mb-md-0">Western Australia</p>
            </MDBCol>
            <MDBCol md="3">
              <MDBBtn tag="a" floating color="blue" className="accent-1">
                <MDBIcon icon="phone" />
              </MDBBtn>
              <p>+ 61 414 662 415</p>
              <p className="mb-md-0">Mon - Fri, 8:00-22:00</p>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn tag="a" floating color="blue" className="accent-1">
                <MDBIcon icon="envelope" />
              </MDBBtn>
              <p>darcy.buttrose@cmitinternational.com.au</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
